import { z } from 'zod';
import { BenefitCategory, BenefitStatus } from 'store/benefits';
import { EmbeddedCustomCategory } from 'store/custom-categories';
import {
  benefitByEmployeeGroupFiltersSchema,
  benefitsByEmployeeFiltersSchema,
  employeesByEmployeeGroupFiltersSchema,
  employeeGroupsByBenefitFiltersSchema,
  employeeGroupsByEmployeeFiltersSchema,
  employeesByBenefitFiltersSchema,
} from './assignments.schemas';

export const assignmentStatuses = ['on', 'off'] as const;
export type AssignmentStatus = (typeof assignmentStatuses)[number];

export interface EmployeeByBenefit {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  assignmentStatus: AssignmentStatus;
  customCategories: EmbeddedCustomCategory[];
}

export interface EmployeeGroupByBenefit {
  id: string;
  name: string;
  assignmentStatus: AssignmentStatus;
}

export interface BenefitByEmployee {
  id: string;
  name: string;
  benefitStatus: BenefitStatus;
  assignmentStatus: AssignmentStatus;
  category: BenefitCategory;
  investment: number;
}

export interface EmployeeGroupByEmployee {
  id: string;
  name: string;
  employeeCount: number;
  assignmentStatus: AssignmentStatus;
}

export interface BenefitByEmployeeGroup {
  id: string;
  name: string;
  benefitStatus: BenefitStatus;
  assignmentStatus: AssignmentStatus;
  category: BenefitCategory;
  investment: number;
}

export interface EmployeeByEmployeeGroup {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  jobTitle: string;
  assignmentStatus: AssignmentStatus;
  customCategories: EmbeddedCustomCategory[];
}

export type EmployeesByBenefitFilters = z.infer<
  typeof employeesByBenefitFiltersSchema
>;

export type EmployeeGroupsByBenefitFilters = z.infer<
  typeof employeeGroupsByBenefitFiltersSchema
>;

export type BenefitsByEmployeeFilters = z.infer<
  typeof benefitsByEmployeeFiltersSchema
>;

export type EmployeeGroupsByEmployeeFilters = z.infer<
  typeof employeeGroupsByEmployeeFiltersSchema
>;

export type BenefitByEmployeeGroupFilters = z.infer<
  typeof benefitByEmployeeGroupFiltersSchema
>;

export type EmployeesByEmployeeGroupFilters = z.infer<
  typeof employeesByEmployeeGroupFiltersSchema
>;

export interface AssignmentHistoryEntry {
  id: string;
  from: string;
  to: string;
}
