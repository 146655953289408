import { z } from 'zod';
import { BenefitCategory, BenefitStatus } from 'store/benefits';
import { AssignmentType } from 'store/benefits-assignments';
import { EmbeddedCustomCategory } from 'store/custom-categories';
import { AssignmentStatus } from 'store/assignments';
import {
  employeeGroupBenefitsFiltersSchema,
  employeeGroupEmployeesFiltersSchema,
} from './employee-groups.schemas';

export const employeeInGroupStatuses = ['included', 'excluded'] as const;
export type EmployeeInGroupStatus = (typeof employeeInGroupStatuses)[number];

export interface EmployeesGroup {
  id: string;
  name: string;
}

export interface EmployeeGroupBenefit {
  id: string;
  name: string;
  category: BenefitCategory;
  status: BenefitStatus;
  investmentAmount: number | null;
  assignmentStatus: AssignmentStatus;
  assignments: Array<{
    status: AssignmentStatus;
    type: AssignmentType;
    startsAt: string;
    endsAt: string | null;
  }>;
}

export interface EmployeeGroupEmployee {
  id: string;
  fullName: string;
  position: string;
  inGroupStatus: EmployeeInGroupStatus;
  customCategories: EmbeddedCustomCategory[];
}

export interface EmployeesGroupsFilters {
  name: string;
}

export type EmployeeGroupBenefitsFilters = z.infer<
  typeof employeeGroupBenefitsFiltersSchema
>;

export type EmployeeGroupEmployeesFilters = z.infer<
  typeof employeeGroupEmployeesFiltersSchema
>;
