import { useMutation, useQueryClient } from '@tanstack/react-query';
import { expensesKeys } from 'store/expenses';
import { converters as filesConverters } from 'store/files';
import { assignmentsKeys } from 'store/assignments';
import { loaders } from './employees.loaders';
import { employeesKeys } from './employees.queries';

export const useCreateEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries(employeesKeys.lists);
    },
  });
};

export const useBulkUpdateEmployees = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.bulkUpdateEmployees, {
    onSuccess: () => {
      queryClient.invalidateQueries(employeesKeys.all);
    },
  });
};

export const useAssignEmployeeGroups = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.assignEmployeeGroups, {
    onSuccess: () => {
      queryClient.invalidateQueries(employeesKeys.all);
      queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useSendInvitations = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.sendInvitations, {
    onSuccess: () => {
      queryClient.invalidateQueries(employeesKeys.all);
    },
  });
};

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteEmployee, {
    onSuccess: () => {
      queryClient.invalidateQueries(employeesKeys.all);
    },
  });
};

export const useCreateEmployeeExpense = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.createExpense, {
    onSuccess: (_, { employeeId }) => {
      queryClient.invalidateQueries(employeesKeys.detail(employeeId));
      queryClient.invalidateQueries(expensesKeys.lists);
    },
  });
};

export const useExportToXls = () => {
  return useMutation(loaders.exportXls, {
    onSuccess: filesConverters.export,
  });
};
