import { sortFiltersSchema } from 'utils/schemas';
import { z } from 'zod';

export const getFormData = (
  data: Array<{ field: string; value: File | string }>,
) => {
  const formData = new FormData();

  data.forEach(({ field, value }) => formData.append(field, value));

  return formData;
};

export const exportXlsxConfig = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/xlsx',
  },
  responseType: 'arraybuffer',
} as const;

export const getSorting = (
  sort: z.infer<typeof sortFiltersSchema>['sort'] | undefined,
) =>
  sort
    ? {
        sorting: sort.columnKey,
        direction: sort.order.toUpperCase(),
      }
    : {};
