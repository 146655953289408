import { useMutation, useQueryClient } from '@tanstack/react-query';
import { loaders } from './assignments.loaders';
import { assignmentsKeys } from './assignments.queries';

export const useUpdateEmployeeBenefitAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateEmployeeBenefitAssignmentHistoryEntry, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useUpdateBenefitEmployeeGroupAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.updateBenefitEmployeeGroupAssignmentHistoryEntry, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useUpdateEmployeeEmployeeGroupAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(
    loaders.updateEmployeeEmployeeGroupAssignmentHistoryEntry,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(assignmentsKeys.all);
      },
    },
  );
};

export const useDeleteEmployeeBenefitAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteEmployeeBenefitAssignmentHistoryEntry, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useDeleteBenefitEmployeeGroupAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(loaders.deleteBenefitEmployeeGroupAssignmentHistoryEntry, {
    onSuccess: () => {
      return queryClient.invalidateQueries(assignmentsKeys.all);
    },
  });
};

export const useDeleteEmployeeEmployeeGroupAssignmentHistoryEntry = () => {
  const queryClient = useQueryClient();

  return useMutation(
    loaders.deleteEmployeeEmployeeGroupAssignmentHistoryEntry,
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(assignmentsKeys.all);
      },
    },
  );
};
