import { api } from 'api/api';
import { Endpoints } from 'api/constants';
import {
  IAssignmentHistoryEntry,
  IBenefitByEmployee,
  IBenefitByEmployeeGroup,
  IEmployeeByBenefit,
  IEmployeeByEmployeeGroup,
  IEmployeeGroupByBenefit,
  IEmployeeGroupByEmployee,
} from 'types/income';
import { TableDataResponse } from 'types/Table';
import { CustomCategory } from 'store/custom-categories';
import {
  BenefitByEmployeeGroupFilters,
  BenefitsByEmployeeFilters,
  EmployeesByEmployeeGroupFilters,
  EmployeeGroupsByEmployeeFilters,
  EmployeesByBenefitFilters,
} from './assignments.types';

interface GetEmployeesByBenefit {
  benefitId: string;
  filters: Partial<EmployeesByBenefitFilters>;
}

interface GetEmployeeGroupsByBenefit {
  benefitId: string;
  filters: Partial<EmployeesByBenefitFilters>;
}

interface GetBenefitsByEmployee {
  employeeId: string;
  filters: Partial<BenefitsByEmployeeFilters>;
}

interface GetEmployeeGroupsByEmployee {
  employeeId: string;
  filters: Partial<EmployeeGroupsByEmployeeFilters>;
}

interface GetBenefitsByEmployeeGroup {
  employeeGroupId: string;
  filters: Partial<BenefitByEmployeeGroupFilters>;
}

interface GetEmployeesByEmployeeGroup {
  employeeGroupId: string;
  filters: Partial<EmployeesByEmployeeGroupFilters>;
}

interface GetEmployeeBenefitAssignmentHistory {
  employeeId: string;
  benefitId: string;
}

interface GetBenefitEmployeeGroupAssignmentHistory {
  benefitId: string;
  employeeGroupId: string;
}

interface GetEmployeeEmployeeGroupAssignmentHistory {
  employeeId: string;
  employeeGroupId: string;
}

interface UpdateEmployeeBenefitAssignmentHistoryEntry {
  assignmentId: string;
  data: { from: string; to?: string };
}

interface UpdateBenefitEmployeeGroupAssignmentHistoryEntry {
  assignmentId: string;
  data: { from: string; to?: string };
}

interface UpdateEmployeeEmployeeGroupAssignmentHistoryEntry {
  assignmentId: string;
  data: { from: string; to?: string };
}

interface DeleteEmployeeBenefitAssignmentHistoryEntry {
  assignmentId: string;
}

interface DeleteBenefitEmployeeGroupAssignmentHistoryEntry {
  assignmentId: string;
}

interface DeleteEmployeeEmployeeGroupAssignmentHistoryEntry {
  assignmentId: string;
}

export const loaders = {
  getEmployeesByBenefit: ({ benefitId, filters }: GetEmployeesByBenefit) => {
    return api<
      TableDataResponse<IEmployeeByBenefit> & {
        customCategories?: Pick<CustomCategory, 'id' | 'name'>[];
      }
    >({
      url: Endpoints.clientAdmin.assignments.benefits.employees.root.replace(
        ':benefitId',
        benefitId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeIds: filters.employees,
          employeeGroupIds: filters.employeeGroups,
          companyIds: filters.companies,
          departmentIds: filters.departments,
          assignmentStatus: filters.assignmentStatus,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValueIds: filters.customCategoryValue,
        },
      },
    });
  },
  getEmployeeGroupsByBenefit: ({
    benefitId,
    filters,
  }: GetEmployeeGroupsByBenefit) => {
    return api<TableDataResponse<IEmployeeGroupByBenefit>>({
      url: Endpoints.clientAdmin.assignments.benefits.employeeGroups.root.replace(
        ':benefitId',
        benefitId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeGroupIds: filters.employeeGroups,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getBenefitsByEmployee: ({ employeeId, filters }: GetBenefitsByEmployee) => {
    return api<TableDataResponse<IBenefitByEmployee>>({
      url: Endpoints.clientAdmin.assignments.employees.benefits.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        search: filters.search || '',
        filter: {
          benefitIds: filters.benefits,
          categories: filters.categories,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getEmployeeGroupsByEmployee: ({
    employeeId,
    filters,
  }: GetEmployeeGroupsByEmployee) => {
    return api<TableDataResponse<IEmployeeGroupByEmployee>>({
      url: Endpoints.clientAdmin.assignments.employees.employeeGroups.root.replace(
        ':employeeId',
        employeeId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeGroupIds: filters.employeeGroups,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getBenefitsByEmployeeGroup: ({
    employeeGroupId,
    filters,
  }: GetBenefitsByEmployeeGroup) => {
    return api<TableDataResponse<IBenefitByEmployeeGroup>>({
      url: Endpoints.clientAdmin.assignments.employeeGroups.benefits.root.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          employeeGroupIds: filters.employeeGroups,
          benefitIds: filters.benefits,
          categories: filters.categories,
          assignmentStatus: filters.assignmentStatus,
        },
      },
    });
  },
  getEmployeesByEmployeeGroup: ({
    employeeGroupId,
    filters,
  }: GetEmployeesByEmployeeGroup) => {
    return api<
      TableDataResponse<IEmployeeByEmployeeGroup> & {
        customCategories?: Pick<CustomCategory, 'id' | 'name'>[];
      }
    >({
      url: Endpoints.clientAdmin.assignments.employeeGroups.employees.root.replace(
        ':employeeGroupId',
        employeeGroupId,
      ),
      params: {
        page: filters.page,
        pageSize: filters.pageSize,
        filter: {
          companyIds: filters.companies,
          departmentIds: filters.departments,
          employeeIds: filters.employees,
          assignmentStatus: filters.assignmentStatus,
          customCategoryId: filters.customCategory || undefined,
          customCategoryValueIds: filters.customCategoryValue,
        },
      },
    });
  },

  getEmployeeBenefitAssignmentHistory: ({
    employeeId,
    benefitId,
  }: GetEmployeeBenefitAssignmentHistory) => {
    return api<{ data: IAssignmentHistoryEntry[] }>({
      url: Endpoints.clientAdmin.assignments.history.employee_benefit
        .replace(':employeeId', employeeId)
        .replace(':benefitId', benefitId),
    });
  },
  getBenefitEmployeeGroupAssignmentHistory: ({
    benefitId,
    employeeGroupId,
  }: GetBenefitEmployeeGroupAssignmentHistory) => {
    return api<{ data: IAssignmentHistoryEntry[] }>({
      url: Endpoints.clientAdmin.assignments.history.benefit_employeeGroup
        .replace(':employeeGroupId', employeeGroupId)
        .replace(':benefitId', benefitId),
    });
  },
  getEmployeeEmployeeGroupAssignmentHistory: ({
    employeeId,
    employeeGroupId,
  }: GetEmployeeEmployeeGroupAssignmentHistory) => {
    return api<{ data: IAssignmentHistoryEntry[] }>({
      url: Endpoints.clientAdmin.assignments.history.employee_employeeGroup
        .replace(':employeeId', employeeId)
        .replace(':employeeGroupId', employeeGroupId),
    });
  },
  updateEmployeeBenefitAssignmentHistoryEntry: ({
    assignmentId,
    data,
  }: UpdateEmployeeBenefitAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.benefits.byId.replace(
        ':assignmentId',
        assignmentId,
      ),
      data: {
        from: data.from,
        to: data.to,
      },
      method: 'patch',
    });
  },
  updateBenefitEmployeeGroupAssignmentHistoryEntry: ({
    assignmentId,
    data,
  }: UpdateBenefitEmployeeGroupAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.benefits.employeeGroups.byId.replace(
        ':assignmentId',
        assignmentId,
      ),
      data: {
        from: data.from,
        to: data.to,
      },
      method: 'patch',
    });
  },
  updateEmployeeEmployeeGroupAssignmentHistoryEntry: ({
    assignmentId,
    data,
  }: UpdateEmployeeEmployeeGroupAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.employeeGroups.byId.replace(
        ':assignmentId',
        assignmentId,
      ),
      data: {
        from: data.from,
        to: data.to,
      },
      method: 'patch',
    });
  },
  deleteEmployeeBenefitAssignmentHistoryEntry: ({
    assignmentId,
  }: DeleteEmployeeBenefitAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.benefits.byId.replace(
        ':assignmentId',
        assignmentId,
      ),
      method: 'delete',
    });
  },
  deleteBenefitEmployeeGroupAssignmentHistoryEntry: ({
    assignmentId,
  }: DeleteBenefitEmployeeGroupAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.benefits.employeeGroups.byId.replace(
        ':assignmentId',
        assignmentId,
      ),
      method: 'delete',
    });
  },
  deleteEmployeeEmployeeGroupAssignmentHistoryEntry: ({
    assignmentId,
  }: DeleteEmployeeEmployeeGroupAssignmentHistoryEntry) => {
    return api({
      url: Endpoints.clientAdmin.assignments.employees.employeeGroups.byId.replace(
        ':assignmentId',
        assignmentId,
      ),
      method: 'delete',
    });
  },
};
